import cookieService from './cookieService';
import axios from 'axios';

export class RequestService {
  static async makeGetRequest(path) {
    let url = process.env.VUE_APP_DOMAIN + path;
    if (path.indexOf('http') === 0 || path.indexOf('https') === 0) {
      url = path;
    }

    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        ...(token) && {Authorization: token}
      }
    };
    return axios.get(url, config)
      .then((value) => {
        return value;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          cookieService.setCookie('token', null);
          window.location.replace('/login?isSessionExpired=1');
        }
      });
  }
}

const requestService = RequestService;
export default requestService;
